import React, { Component } from "react";
import axios from "axios";
import * as ConstantVar from "../../constants_var.js";
import NumberFormat from "react-number-format";
import moment from "moment";

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noOfDeposit: null,
      bankTransferDeposit: null,
      providusDeposit: null,
      suntrustDeposit: null,
      cardDeposit: null,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount() {
    this.getDepositReportRange();
  }

  getDepositReportRange = () => {
    let self = this;
    axios
      .post(ConstantVar.API_BASE_URL + "/support/deposit_report_range", {
        token: localStorage.getItem("supportToken"),
        start_date: self.state.start_date,
        end_date: self.state.end_date,
      })
      .then((response) => {
        let data = response.data.data;
        self.setState({
          isLoading: false,
          noOfDeposit: data.number_of_deposits,
          bankTransferDeposit: data.bank_transfer_deposit,
          suntrustDeposit: data.suntrust_deposit,
          providusDeposit: data.providus_deposit,
          cardDeposit: data.card_deposit,
        });
      });
  };

  render() {
    return (
      <div className="container">
        <div className="card shadow col-md-6 p-3 mx-auto">
          <div className="row">
            <h4 className="col-md-9">Deposit</h4>
          </div>

          <div className="form-inline">
            <label>From:</label>
            <input
              type="date"
              className="ml-2 mr-2 form-control"
              name="start_date"
              value={this.state.start_date}
              onChange={this.handleChange}
            />
            <label>To:</label>
            <input
              type="date"
              className="ml-2 mr-2 form-control"
              name="end_date"
              value={this.state.end_date}
              onChange={this.handleChange}
            />
            <button className="btn btn-primary" onClick={this.getDepositReportRange}>
              View
            </button>
          </div>

          <table className="mt-2">
            <tbody>
              <tr>
                <th className="p-3">No. Deposit</th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.noOfDeposit}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-3">Suntrust Deposits</th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.suntrustDeposit}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-3">Providus Deposits</th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.providusDeposit}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-3">Card Deposit </th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.cardDeposit}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th className="p-3">Total </th>
                <td className="p-3">
                  {" "}
                  <NumberFormat
                    value={this.state.cardDeposit + this.state.bankTransferDeposit}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Deposit;
